import * as React from "react"
// import {Link} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import styled from "styled-components"
import {LabelProvider} from "../assets/js/labelDataContext"
import OrderBox from "../components/orders/OrderBox"
import SmallHeroSection from "../components/SmallHeroSection";
import DecorativeSection from "../components/DecorativeSection";
import OrderSend from "../components/orders/OrderSend";

const OrderSendOkPage = () => (
    <Layout page="zamowienieok">
        <Seo title="Zamówienie"/>


        <SmallHeroSection/>
        <DecorativeSection/>
        
        <section> 
          <LabelProvider>
            <OrderSend />
          </LabelProvider>
        </section> 

    </Layout>
)

export default OrderSendOkPage